import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router';
import _ from 'underscore';
import { Global } from '~/App'
import API from '~/API';
import GriddleCasero from '~/components/GriddleCasero'
import moment from 'moment';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from 'recharts';

class Consumos extends Component {
    constructor() {
        super();
        this.state = {
            collapsed: false,
            datos: [],
            um: ""
        }
        this.consultaConsumos = this.consultaConsumos.bind(this);
    }
    componentWillMount() {
        this.Redirect(this.props);
        this.consultaConsumos(this.props);
    }
    componentWillReceiveProps(props) {
        this.Redirect(props)
        if (this.props.Servicio.empresa !== props.Servicio.empresa || this.props.Servicio.idServicio !== props.Servicio.idServicio) {
            this.consultaConsumos(props);
        }
    }
    Redirect(props) {
        if (!this.props.Servicio.idServicio) {
            props.history.replace("/administra")
        }
    }
    async consultaConsumos(props) {
        if (!props.Servicio.idServicio || !props.Servicio.empresa) {
            return;
        }
        var datos = await API.Call(`Consulta/${props.Servicio.empresa}/Cartola/Consumos/${props.Servicio.idServicio}/${moment().add(-13, 'month').format("DDMMYYYY")}/${moment().add(1, 'month').format("DDMMYYYY")}`);
        var um = ""
        datos = _.map(datos.Documentos, e => {
            e.fechaformato = moment(e.fecha_lectura).format("MMM YYYY");
            um = e.nom_unid_med||"m3";
            return e;
        }).reverse()
        this.setState({ datos, um });
    }
    render() {
        const columns = [
            { key: "nombre_clave_lectura", name: "Tipo Lectura" },
            { key: "fechaformato", name: "Periodo" },
            { key: "fecha_lectura", name: "Fecha Lectura", formatter: "fecha", right: true },
            // { key: "fecha_vcto", name: "Vencimiento", formatter: "fecha" },
            { key: "consumo_facturado_ap", name: `Agua Potable [${this.state.um}]`, right: true, formatter: "derecha" },
            { key: "consumo_facturado_al", name: `Alcantarillado [${this.state.um}]`, right: true, formatter: "derecha" }
        ];
        var rows = _.sortBy(this.state.datos || [], d => moment(d.fecha)).reverse();
        return (
            <div>
                <Row>
                    <Col sm={12}>
                        <ResponsiveContainer width={"100%"} height={250}>
                            <BarChart width={730} height={250} data={this.state.datos}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="fechaformato" />
                                <YAxis tickFormatter={t=>(Global.FormatearNumero(t)+` ${this.state.um}`)}/>
                                <Tooltip formatter={(value) => [Global.FormatearNumero(value)+` [${this.state.um}]`,"Consumo"]}/>
                                <Bar dataKey="consumo_facturado_ap" className="grafico-barras1" />
                            </BarChart>
                        </ResponsiveContainer>
                        <hr />
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className={`bordeIzquierdo tablaConsumos ${this.state.um}`}>
	                    {this.state.datos?.length?<GriddleCasero
	                        data = {rows}
	                        columns = {columns}
	                    />:null}
                    </Col>
                </Row>
            </div>
        )
    }
}


export default withRouter(Consumos);