import React, { Component } from 'react';
import ModalSimulacionConvenio from './ModalSimulacionConvenio';
import ModalDetalleConvenio from './ModalDetalleConvenio';
import { Modal, ModalBody, ModalHeader, Spinner, ModalFooter, Button, Form, ButtonGroup, FormGroup, InputGroup, Col, Row, Alert, Label, Input, InputGroupAddon, FormFeedback, Card, CardFooter, CardHeader, CardBody } from 'reactstrap';
import { Global } from '~/App';
import _ from 'underscore';
import s from 'underscore';
import moment from 'moment';
import API from '~/API';

export default class ModalConvenio extends Component {
    constructor() {
        super();
        this.state = {
            abrir: false,
            pasoConvenio: 0,
            showSimulacion: false,
            Simulacion: [],
            loading: false,
            ciclosGracia: 0,
            numeroCuotas: 0,
            errorConvenio: false,
            convenios: [],
            aceptaPoliticas: false
        }
        this.SimularConvenio = this.SimularConvenio.bind(this)
        this.toggle = this.toggle.bind(this);
        this.ToggleModalSimulacion = this.ToggleModalSimulacion.bind(this);
        this.vaciarCampos = this.vaciarCampos.bind(this);
        try {
            this.inIframe = window.self !== window.top
        } catch {
            this.inIframe = true;
        }
    }
    ToggleModalSimulacion() {
        this.setState({ showSimulacion: !this.state.showSimulacion });
    }
    componentDidMount() {
        this.vaciarCampos();
    }
    cargarDatosConvenio() {
        var servicio = this.props.Servicio;
        var tipoConvenio = this.state.tipoConvenio;
        var convenio = _.find(this.state.convenios, c => c.idTipoConvenio == tipoConvenio);
        var labelCuota = convenio.tipoCuota == 1 ?
            "Valor cuota" :
            (_.contains([2, 3, 5], convenio.tipoCuota) ?
                "Cantidad de cuotas" :
                "% de facturación")
        var montoPieMin = convenio.tipoPie == 1 ? 0 : convenio.tipoPie == 2 ? convenio.pieMin : Math.ceil(convenio.pieMin * servicio.deuda_actual / 100);
        var montoPieMax = convenio.tipoPie == 1 ? 0 : convenio.tipoPie == 2 ? convenio.pieMax : Math.ceil(convenio.pieMax * servicio.deuda_actual / 100);
        var minimoObligatorio = convenio.topePieMin || 0;
        if (montoPieMin < minimoObligatorio) {
            montoPieMin = minimoObligatorio
        }
        var montoPie = montoPieMin;
        var cuotaMax = Math.min(convenio.valorCuotaMin>0?Math.floor((servicio.deuda_actual - montoPie)/convenio.valorCuotaMin):convenio.cuotaMax,convenio.cuotaMax)
        this.setState({
            labelCuota,
            numeroCuotas: convenio.cuotaMin,
            cuotaMax,
            montoConvenido: servicio.deuda_actual - montoPie,
            deudaPagar: servicio.deuda_actual,
            montoPie,
            montoPieMin,
            montoPieMax,
            ciclosGracia: 0
        }/* , () => {this.SimularConvenio()} */);
    }
    SimularConvenio(levantarModal = true) {
        this.setState({ loading: true })
        var datos = {
            IdServicio: this.props.Servicio.idServicio,
            IdTipoConvenio: this.state.tipoConvenio,
            MontoConvenido: this.state.montoConvenido,
            MontoPie: this.state.montoPie,
            NoCiclosGracia: this.state.ciclosGracia,
            Cuotas: this.state.numeroCuotas//NoCuotas: this.state.numeroCuotas
        };
        var url = `Consulta/${this.props.Servicio.empresa}/SimularConvenio/`;
        var s = { ...this.props.Servicio, Empresa: this.props.Servicio.Empresa ? this.props.Servicio.Empresa : Global.empresas.find(o => o.key == this.props.Servicio.empresa) || {} }
        var formaPago = s.Empresa.otros?.RecaudadorDefecto;
        if (!_.isEmpty(s.Empresa.recaudadores) && !formaPago) {
            formaPago = Object.keys(s.Empresa.recaudadores)[0];
        }
        API.Call(url, datos).then((data) => {
            this.setState({ formaPago, simulacionConvenio: data, showSimulacion: levantarModal, loading: false, errorConvenio: false });
            document.getElementById('Portal-Clientes-resultado-simulacion').scrollIntoView({ behavior: 'smooth', block: 'start' });
        }).catch((error, xhr, response) => {
            this.setState({ loading: false, errorConvenio: true })
            console.log("error", error);
        });
    }
    vaciarCampos() {
        var servicio = this.props.Servicio;
        this.setState({
            nombre: Global.Usuario.nombre + " " + Global.Usuario.apellido,
            direccion: servicio.dir_instal,
            mail: Global.Usuario.email,
            rut: Global.Usuario.rut,
            telefono: Global.Usuario.phoneNumber,
            celular: Global.Usuario.phoneNumber,
            deudaVencida: servicio.deuda_vencida,
            deudaActual: servicio.deuda_actual,
            deudaTotal: servicio.deuda_total,
            errorConvenio: false,
            loading: false,
            ciclosGracia: 0,
            aceptaPoliticas: false,
            pasoConvenio: 0,
            showSimulacion: false,
            Simulacion: [],
        });
    }
    toggle(e) {
        e.preventDefault();
        if (this.state.abrir) {
            this.props.ActualizarPadre();
            this.setState({ abrir: false })
        } else {
            if (_.isEmpty(this.state.convenios)) {
                API.Call(`Consulta/${this.props.Servicio.empresa}/ConveniosDisponibles`, { IdServicio: this.props.Servicio.idServicio }).then(e => this.setState({ ...e, abrir: true }));
            } else {
                this.setState({ abrir: true })
            }
        }
    }
    render() {
        const closeBtn = <button className="close" onClick={this.toggle}>&times;</button>;
        var servicio = { ...this.props.Servicio, Empresa: this.props.Servicio.Empresa ? this.props.Servicio.Empresa : Global.empresas.find(o => o.key == this.props.Servicio.empresa) || {} }
        var recaudadores = servicio.Empresa.recaudadores || {};
        var tipoConvenioActual = this.state.tipoConvenioActual || {};
        var convenio = _.find(this.state.convenios, c => c.idTipoConvenio == this.state.tipoConvenio);
        var deuda = servicio.deuda_actual;

        var cuotaMinValida = true;
        var cuotaMin = _.max(this.state.simulacionConvenio, s => s.monto_total_cuota).monto_total_cuota;
        if (convenio && cuotaMin < convenio.valorCuotaMin) {
            cuotaMinValida = false;
        }
        const disableButton = (this.state.bloquear_siguiente) || !cuotaMinValida || !this.state.mail ||
            (this.state.montoPie > this.state.montoPieMax || this.state.montoPie < this.state.montoPieMin) ||
            (convenio && (this.state.numeroCuotas > this.state.cuotaMax || this.state.numeroCuotas < convenio.cuotaMin)) ||
            !this.state.tipoConvenio || !this.state.celular || !this.state.telefono ||
            !this.state.rut || !this.state.direccion || !this.state.mail ||
            (this.state.montoDeuda < deuda.deuda_total && this.state.pasoConvenio == 0) ||
            (this.state.validacionGlobal && this.state.pasoConvenio == 1);
        if (servicio) {
            return (
                !deuda ? '' :
                    <div>
                        <small>
                            <a href="#" onClick={this.toggle}>Convenios</a>
                        </small>
                        {this.state.abrir && <Modal onOpened={this.vaciarCampos} scrollable size="lg" wrapClassName="asyntec-portal-clientes" isOpen={this.state.abrir} toggle={this.toggle}>
                            <Form
                                method="post"
                                target={this.inIframe ? "_blank" : null}
                                className="row"
                                action={this.state.montoPie == 0 ? `${Global.UrlApi}/Recaudacion/${servicio.empresa}/OrdenDePago/Generar/Convenio` : this.state.formaPago ? `${Global.UrlApi}/Recaudacion/${servicio.empresa}/OrdenDePago/${Global.toTitleCase(this.state.formaPago)}/Convenio` : ""}>
                                <div className="modal-content" style={{ padding: "1rem" }}>
                                    <ModalHeader close={closeBtn}>
                                        Creacion convenio {servicio.idServicio}
                                    </ModalHeader>
                                    <ModalBody>
                                        {Global.convenio}
                                        <Row id="Portal-Clientes-inicio-simulacion">
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Label for="tipoConvenio">
                                                        Tipo convenio
                                                    </Label>
                                                    <InputGroup>
                                                        <Input
                                                            value={this.state.tipoConvenio}
                                                            onChange={(evt) => { this.setState({ simulacionConvenio: null, tipoConvenio: evt.target.value > 0 || evt.target.value === 0 ? evt.target.value : this.state.tipoConvenio }, () => { this.cargarDatosConvenio() }) }}
                                                            bsSize="sm"
                                                            type="select"
                                                            invalid={!this.state.tipoConvenio}
                                                            name="tipoConvenio" >
                                                            <option value={null}>Selecciona tipo convenio</option>
                                                            {this.state.convenios.map((convenio, i) => {
                                                                return <option key={i} value={convenio.idTipoConvenio}>{convenio.nomTipoConvenio}</option>
                                                            })}
                                                        </Input>
                                                        <FormFeedback>Requerido</FormFeedback>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="deudaActual">
                                                        Deuda actual
                                                    </Label>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="append">
                                                            {tipoConvenioActual.simboloMoneda ? tipoConvenioActual.simboloMoneda : "$"}
                                                        </InputGroupAddon>
                                                        <Input
                                                            value={Global.FormatearNumero(this.state.deudaActual)}
                                                            bsSize="sm"
                                                            type="text"
                                                            readOnly />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <Label for="montoConvenido">Monto a Convenir</Label>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="append">
                                                            {tipoConvenioActual.simboloMoneda ? tipoConvenioActual.simboloMoneda : "$"}
                                                        </InputGroupAddon>
                                                        <Input
                                                            value={Global.FormatearNumero(this.state.montoConvenido)}
                                                            bsSize="sm"
                                                            readOnly
                                                            type="text" />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {this.state.tipoConvenio && convenio ? <>
                                            <Row>
                                                <Col sm={convenio.maxCiclosGracia ? 4 : 6}>
                                                    <FormGroup>
                                                        <Label for="numeroCuotas">Cuotas</Label>
                                                        <InputGroup>
                                                            <Input
                                                                value={this.state.numeroCuotas}
                                                                onChange={(evt) => {
                                                                    this.setState({ simulacionConvenio: null, numeroCuotas: evt.target.value }/* , () => {this.SimularConvenio()} */);
                                                                }}
                                                                bsSize="sm"
                                                                type="select"
                                                                invalid={this.state.numeroCuotas > this.state.cuotaMax || this.state.numeroCuotas < convenio.cuotaMin} >
                                                                {Array.from({ length: this.state.cuotaMax - convenio.cuotaMin + 1 }, (v, k) => convenio.cuotaMin + k).map(cuota => {
                                                                    return <option key={cuota} value={cuota}>{cuota}</option>
                                                                })}
                                                            </Input>
                                                            <FormFeedback>Requerido</FormFeedback>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                                {convenio.maxCiclosGracia ? <Col sm={4}>
                                                    <FormGroup>
                                                        <Label for="NoCiclosGracia">Períodos libres de pago</Label>
                                                        <InputGroup>
                                                            <Input
                                                                value={this.state.ciclosGracia}
                                                                onChange={(evt) => {
                                                                    this.setState({ simulacionConvenio: null, ciclosGracia: evt.target.value }/* , () => {this.SimularConvenio()} */);
                                                                }}
                                                                bsSize="sm"
                                                                type="select"
                                                                invalid={this.state.ciclosGracia > convenio.maxCiclosGracia} >
                                                                {Array.from({ length: convenio.maxCiclosGracia + 1 }, (v, k) => k).map(ciclo => {
                                                                    return <option key={ciclo} value={ciclo}>{ciclo ? ciclo : "Sin períodos libres de pago"}</option>
                                                                })}
                                                            </Input>
                                                            <FormFeedback>Requerido</FormFeedback>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col> : null}
                                                <Col sm={convenio.maxCiclosGracia ? 4 : 6}>
                                                    <FormGroup>
                                                        <Label for="montoPie">Pie</Label>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="append">
                                                                {tipoConvenioActual.simboloMoneda ? tipoConvenioActual.simboloMoneda : "$"}
                                                            </InputGroupAddon>
                                                            <Input
                                                                value={Global.FormatearNumero(this.state.montoPie)}
                                                                onChange={(evt) => {
                                                                    var val = evt.target.value;
                                                                    var stringLimpio = val;
                                                                    while (s.contains(stringLimpio, ".")) {
                                                                        stringLimpio = stringLimpio.replace(".", "");
                                                                    }
                                                                    var montoPie = !val ? 0 : parseInt(stringLimpio);
                                                                    var montoConvenido = servicio.deuda_actual - montoPie
                                                                    if (montoPie > this.state.montoPieMax) {
                                                                        montoConvenido = 0;
                                                                    }
                                                                    var cuotaMax = Math.min(convenio.valorCuotaMin>0?Math.floor((servicio.deuda_actual - montoPie)/convenio.valorCuotaMin):convenio.cuotaMax,convenio.cuotaMax)
                                                                    this.setState({ simulacionConvenio: null, montoPie, montoConvenido, cuotaMax }/* , () => {this.SimularConvenio()} */);
                                                                }}
                                                                invalid={this.state.montoPie > this.state.montoPieMax || this.state.montoPie < this.state.montoPieMin}
                                                                bsSize="sm"
                                                                type="text" />
                                                            <FormFeedback>Entre $ {Global.FormatearNumero(this.state.montoPieMin)} y $ {Global.FormatearNumero(this.state.montoPieMax)}</FormFeedback>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Button
                                                        disabled={
                                                            (this.state.montoPie > this.state.montoPieMax || this.state.montoPie < this.state.montoPieMin)
                                                            || (this.state.numeroCuotas > this.state.cuotaMax || this.state.numeroCuotas < convenio.cuotaMin)
                                                            || !this.state.tipoConvenio
                                                        }
                                                        style={{ float: "right" }} onClick={() => this.SimularConvenio()} color="primary">Revisar Convenio</Button>
                                                </Col>
                                            </Row>
                                        </> : ''}
                                        {this.state.errorConvenio && <Alert color="danger"><p>No pudimos generar el convenio con las condiciones seleccionadas.</p><p>Intente con otras condiciones o contáctenos para revisar más opciones de convenios.</p></Alert>}
                                        {this.state.loading && <Spinner animation="border" role="status">
                                            Cargando...
                                        </Spinner>}
                                        {!_.isEmpty(this.state.simulacionConvenio) ?
                                            <>
                                                <br /><br />
                                                <h5 className="modal-title">Simulación <Button style={{ float: "right" }} onClick={() => { this.setState({ simulacionConvenio: null }); document.getElementById('Portal-Clientes-inicio-simulacion').scrollIntoView({ behavior: 'smooth', block: 'start' }) }} color="secondary">Generar Nuevo Convenio</Button></h5>
                                                <br />
                                                <Row id="Portal-Clientes-resultado-simulacion">
                                                    <Col>
                                                        <ModalSimulacionConvenio Cuotas={this.state.simulacionConvenio} SinModal />
                                                    </Col>
                                                </Row>
                                            </>
                                            : ""}
                                        {!_.isEmpty(this.state.simulacionConvenio) && this.state.montoPie ?
                                            <div style={{ textAlign: "center" }}>
                                                <br />
                                                <hr />
                                                <br />
                                                <Row>
                                                    <Col sm={6}>
                                                        <h5 style={{ display: "inline" }}>Total a pagar</h5> <strong><h5 style={{ display: "inline" }}>$ {Global.FormatearNumero(this.state.montoPie)}</h5> <small>CLP</small></strong>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <h5 className="modal-title">Forma de Pago</h5>
                                                        <br />
                                                        {recaudadores.transbank &&
                                                            <FormGroup className="transbank" check inline>
                                                                <Label check>
                                                                    <Input type="radio" checked={this.state.formaPago == "transbank"} onChange={() => { }} onClick={() => this.setState({ formaPago: "transbank" })} />{' '}
                                                                    <img src="https://cdn.smartx.cl/images/logo-webpay-plus.png" width="60" />
                                                                </Label>
                                                            </FormGroup>
                                                        }
                                                        {recaudadores.flow &&
                                                            <FormGroup className="flow" check inline>
                                                                <Label check>
                                                                    <Input type="radio" checked={this.state.formaPago == "flow"} onChange={() => { }} onClick={() => this.setState({ formaPago: "flow" })} />{' '}
                                                                    <img src="https://recaudador.asyntec.com/logoflow.png" width="60" />
                                                                </Label>
                                                            </FormGroup>}
                                                        {recaudadores.khipu &&
                                                            <FormGroup className="khipu" check inline>
                                                                <Label check>
                                                                    <Input type="radio" checked={this.state.formaPago == "khipu"} onChange={() => { }} onClick={() => this.setState({ formaPago: "khipu" })} />{' '}
                                                                    <img src="https://s3.amazonaws.com/static.khipu.com/buttons/2015/110x50-transparent.png" width="80" />
                                                                </Label>
                                                            </FormGroup>}
                                                        {recaudadores.mach &&
                                                            <FormGroup className="mach" check inline>
                                                                <Label check>
                                                                    <Input type="radio" checked={this.state.formaPago == "mach"} onClick={() => this.setState({ formaPago: "mach" })} />{' '}
                                                                    <img src="https://recaudador.asyntec.com/logomach.png" width="80" />
                                                                </Label>
                                                            </FormGroup>}
                                                        {recaudadores.bci &&
                                                            <FormGroup className="bci" check inline>
                                                                <Label check>
                                                                    <Input type="radio" checked={this.state.formaPago == "bci"} onClick={() => this.setState({ formaPago: "bci" })} />{' '}
                                                                    <img src="https://recaudador.asyntec.com/logobci.png" width="80" />
                                                                </Label>
                                                            </FormGroup>}
                                                        {recaudadores.servipag &&
                                                            <FormGroup className="servipag" check inline>
                                                                <Label check>
                                                                    <Input type="radio" checked={this.state.formaPago == "servipag"} onChange={() => { }} onClick={() => this.setState({ formaPago: "servipag" })} />{' '}
                                                                    <img src="https://cdn.smartx.cl/images/logo-servipag.png" width="60" />
                                                                </Label>
                                                            </FormGroup>}
                                                        {recaudadores.unired &&
                                                            <FormGroup className="unired" check inline>
                                                                <Label check>
                                                                    <Input type="radio" checked={this.state.formaPago == "unired"} onChange={() => { }} onClick={() => this.setState({ formaPago: "unired" })} />{' '}
                                                                    <img src="https://recaudador.asyntec.com/unired.png" width="60" />
                                                                </Label>
                                                            </FormGroup>}
                                                    </Col>
                                                </Row>
                                            </div>
                                            : ""}
                                        {API.Cookie && <input type="hidden" name="token" value={API.Cookie} />}
                                        <input type="hidden" name="idServicio" value={servicio.idServicio} />
                                        <input type="hidden" name="tipoConvenio" value={this.state.tipoConvenio} />
                                        <input type="hidden" name="montoConvenido" value={this.state.montoConvenido} />
                                        <input type="hidden" name="montoPie" value={this.state.montoPie} />
                                        <input type="hidden" name="ciclosGracia" value={this.state.ciclosGracia} />
                                        <input type="hidden" name="numeroCuotas" value={this.state.numeroCuotas} />
                                    </ModalBody>
                                    {/* modal footer */}
                                    <ModalFooter>
                                        {!_.isEmpty(this.state.simulacionConvenio) ?
                                            <>
                                                {this.state.politicas ? <Row>
                                                    <Col xs={12}>
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input disabled={disableButton} checked={this.state.aceptaPoliticas} onChange={e => {
                                                                    this.setState({ aceptaPoliticas: e.target.checked })
                                                                }} type="checkbox" />
                                                                <small>
                                                                    {' ' + this.state.politicas}
                                                                </small>
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row> : null}
                                                {this.state.montoPie ?
                                                    this.state.formaPago ? <Button type="submit" style={{ float: "right" }} color="primary" disabled={disableButton || (this.state.politicas && !this.state.aceptaPoliticas)}>Pagar</Button> : null
                                                    : <Button type="submit" style={{ float: "right" }} color="primary" disabled={disableButton || (this.state.politicas && !this.state.aceptaPoliticas)}>Solicitar</Button>}
                                            </> : null}
                                    </ModalFooter>
                                </div>
                            </Form>
                        </Modal>}
                    </div>
            )
        } else {
            return null;
        }
    }
}