import React, { Component } from 'react';
import { Alert, InputGroup, InputGroupAddon, UncontrolledTooltip, FormFeedback, Spinner, Form, FormGroup, Label, Row, Col, Input, Button, Card, CardHeader, CardBody, CardTitle, CardText } from 'reactstrap';
import { withRouter } from 'react-router';
import _ from 'underscore';
import { Global } from '~/App'
import API from '~/API';
import ModalImagen from '~/components/ModalImagen'
import ModalPorQue from '~/components/ModalPorQue'
import ReCAPTCHA from "react-google-recaptcha";

class IngresaLectura extends Component {
    constructor(props) {
        super();
        this.state = {
            mensajes: [],
            cargando: false,
            lectura: "",
            servicio: (props.IngresarLectura||{}).servicio||"",
            fecha: "",
            documento: "",
            email: "",
            empresa: Global.empresaDefecto,
            captcha: null
        }
    }
    componentWillMount() {
        if (this.props.IngresarLectura) {
            this.setState({ servicio: this.props.IngresarLectura.servicio.toString(), empresa: this.props.IngresarLectura.empresa.toString() });
        }
        if (this.props.Usuario) {
            this.setState({ email: this.props.Usuario.email });
        }
    }
    componentWillReceiveProps(props) {
        this.Redirect(props)
    }
    Redirect(props) {
        if (props.Usuario) {
            //   props.history.replace("/ingresaLectura")
        }
    }
    cambiarNumeroDocumento = event => {
        const { value, maxLength } = event.target;

        const message = value.slice(0, maxLength);

        this.setState({
            documento: message
        });
    }
    handleChangeInput = event => {
        const { value, maxLength } = event.target;

        const message = value.slice(0, maxLength);

        this.setState({
            lectura: message
        });
    };
    async submit(e) {
        e.preventDefault();
        this.setState({ cargando: true })
        var idServicio = this.state.servicio;
        var lectura = this.state.lectura;
        var fecha = this.state.fecha;
        var documento = this.state.documento;
        //estos los deberia ver en la api.
        var usuario = this.props.Usuario || {};
        var nombre = usuario.nombre || "";
        var apellido = usuario.apellido || "";
        var nombreCompleto = nombre != "" ? `${nombre} ${apellido}` : "";
        var rut = usuario.rut || "";
        var email = this.props.Usuario ? this.props.Usuario.email : this.state.email;
        var phoneNumber = usuario.phoneNumber || "";
        var data = {
            IdServicio: idServicio,
            Fecha: fecha,
            Nombre: nombreCompleto,
            Rut: rut,
            Lectura: lectura,
            Email: email,
            Telefono: phoneNumber,
            Documento: documento,
            Empresa: this.state.empresa
        };
        var mensajes = [];
        var valido = true;
        if (email === "") {
            mensajes.push({ tipo: "warning", texto: "Debes ingresar email" });
            valido = false;
        }
        if (lectura === "") {
            mensajes.push({ tipo: "warning", texto: "Debes ingresar lectura" });
            valido = false;
        }
        if (!this.props.IngresarLectura && idServicio === "") {
            mensajes.push({ tipo: "warning", texto: "Debes indicar número de cuenta" });
            valido = false;
        }
        if (!Global.csd && !this.props.IngresarLectura && documento === "") {
            mensajes.push({ tipo: "warning", texto: "Debes ingresar número de documento" });
            valido = false;
        }
        if (idServicio > 2147483647) {
            mensajes.push({ tipo: "warning", texto: "Número se servicio muy grande, debe ser menor a 2147483647" });
            valido = false;
        }
        if (valido) {
            try {
                var response = await API.Call(`Consulta/${this.state.empresa}/Ingresar/Lectura` + (this.props.Usuario && this.props.IngresarLectura ? "" : "/Anonima"), data);
                mensajes.push({ tipo: "success", texto: response });
                if (this.props.FuncionOk) {
                    this.props.FuncionOk();
                    this.props.FuncionCerrar();
                }
            }
            catch (error) {
                mensajes.push({ tipo: "danger", texto: error });
            }
        }
        this.setState({ cargando: false, mensajes });
    }
    render() {
        return (<div>
            <Card className="panel-ingreso-lectura">
                <CardHeader className="titulo-modal">{`Ingreso Lecturas${this.props.Usuario && this.props.IngresarLectura ? ` en cuenta ${Global.AgregaDV(this.props.IngresarLectura.servicio)}` : ""}`}</CardHeader>
                <CardBody className="cuerpo-modal">
                    <Row>
                        <Col sm={12} className="bordeCompleto">
                            <CardTitle>Estimado cliente, puede informarnos su consumo de forma on-line. Para esto debe enviarnos la informacion durante el periodo que comprende los 2 días previos y 2 días posteriores a la fecha de lectura indicada en su último documento de cobro.</CardTitle>
                            <CardText>Ingrese la lectura indicando todos los dígitos del visor, sin considerar decimales. Los decimales se identifican separados por una coma, punto, espacio o por tener distinto color según distinto tipo de medidor.</CardText>
                            {this.props.Usuario && this.props.IngresarLectura || Global.csd ? "" : <CardText>El número de documento debe ser de algun documento emitido en los últimos 12 meses.</CardText>}
                            <Form onSubmit={this.submit.bind(this)}>
                                {this.state.cargando ? <Spinner animation="border" role="status">
                                    Cargando...
                        </Spinner> :
                                    this.props.Usuario && this.props.IngresarLectura ?
                                        <div>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                    <FormGroup>
                                                        <Label for="lectura">Lectura</Label>
                                                        <Input
                                                            onChange={this.handleChangeInput}
                                                            value={this.state.lectura}
                                                            type="number"
                                                            name="lectura"
                                                            id="lectura"
                                                            maxLength="9"
                                                            placeholder="Ingrese lectura"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                {/* <Col md={6} sm={12}>
                                <FormGroup>
                                    <Label for="Fecha">Fecha</Label>
                                    <Input
                                    value={this.state.fecha}
                                    onChange={e => this.setState({ fecha: e.target.value })}
                                    type="date"
                                    name="fecha"
                                    id="Fecha"
                                    placeholder="Fecha lectura"
                                    />
                                </FormGroup> 
                            </Col> */}
                                            </Row>
                                            {/* </Form> */}
                                        </div> :
                                        <div>
                                            {/* <Form onSubmit={this.submit}> */}
                                            <Row className="text-right">
                                                <Col sm={Global.empresas.length > 1 ? 12 : 8}>
                                                    <ModalPorQue />
                                                    <ModalImagen empresa={this.state.empresa} />
                                                    <br />
                                                </Col>
                                            </Row>
                                            <Row>
                                                {Global.empresas.length > 1 &&
                                                    <Col sm={4}>
                                                        <FormGroup>
                                                            <Label for="empresa" >Empresa</Label>
                                                            <Input id="empresa" bsSize="sm" type="select" onChange={e => this.setState({ empresa: e.target.value })}>
                                                                {
                                                                    Global.empresas.map(e => <option value={e.key} selected={e.key == Global.empresaDefecto}>{e.nombre}</option>)
                                                                }
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                }
                                                <Col sm={4}>
                                                    <FormGroup>
                                                        <Label for="servicio" className="numero-cuenta"><span>Número de Cuenta</span></Label>
                                                        <InputGroup>
                                                            <Input
                                                                bsSize="sm"
                                                                value={this.state.servicio}
                                                                onChange={e => this.setState({ servicio: e.target.value })}
                                                                type="number"
                                                                name="servicio"
                                                                id="Servicio"
                                                                /* placeholder="Número de Cuenta" */
                                                                className="text-right"
                                                            />
                                                            <InputGroupAddon id="asyntecPortalDVServicio" addonType="append">
                                                                <Button size="sm" color="link" disabled>{this.state.servicio ? <div> - {Global.DV(this.state.servicio)}</div> : <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}</Button>
                                                                <UncontrolledTooltip placement="top" target="asyntecPortalDVServicio">
                                                                    Dígito Verificador
                                                        </UncontrolledTooltip>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                                {!Global.csd && <Col sm={4}>
                                                    <FormGroup>
                                                        <Label for="documento"><span>Número Documento</span></Label>
                                                        <Input
                                                            value={this.state.documento}
                                                            bsSize="sm"
                                                            onChange={this.cambiarNumeroDocumento.bind(this)}
                                                            type="number"
                                                            name="documento"
                                                            id="Documento"
                                                            /* placeholder="Número de Documento" */
                                                            maxLength="15"
                                                        />
                                                    </FormGroup>
                                                </Col> }
                                            </Row>
                                            <Row className="text-right">
                                                <Col sm={4}>
                                                    <ModalImagen empresa={this.state.empresa} TipoImagen="imagenMedidor" />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={!this.props.Usuario ? 4 : 5}>
                                                    <FormGroup>
                                                        <Label for="lectura">Lectura</Label>
                                                        <Input
                                                            onChange={this.handleChangeInput}
                                                            value={this.state.lectura}
                                                            type="number"
                                                            name="lectura"
                                                            bsSize="sm"
                                                            id="lectura"
                                                            maxLength="9"
                                                            placeholder="Lectura"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                {!this.props.Usuario ?
                                                    <Col sm={5}>
                                                        <FormGroup>
                                                            <Label for="Email">Correo electrónico</Label>
                                                            <Input
                                                                value={this.state.email}
                                                                invalid={!Global.ValidateEmail(this.state.email)}
                                                                onChange={e => this.setState({ email: e.target.value })}
                                                                type="email"
                                                                bsSize="sm"
                                                                name="email"
                                                                id="Email"
                                                                placeholder="Ingresa email"
                                                            />
                                                            <FormFeedback>Debe ser un correo válido</FormFeedback>
                                                        </FormGroup>
                                                    </Col> : ""}
                                            </Row>
                                            {Global.csd && <Row>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <ReCAPTCHA
                                                            sitekey={Global.apiKeyCaptcha}
                                                            onChange={value => this.setState({ captcha: value })}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>}
                                        </div>
                                }
                                {_.map(this.state.mensajes, (m, i) => <Alert key={i} color={m.tipo}>
                                    {m.texto}
                                </Alert>)
                                }
                                <br />
                                <Row>
                                    <Col sm={this.props.Usuario && this.props.IngresarLectura ? 6 : 3}>
                                        <Button block type="submit" disabled={!this.state.lectura || !this.state.servicio || (!this.props.Usuario && !Global.ValidateEmail(this.state.email)) || this.state.cargando ||(!this.props.FuncionCerrar && Global.csd&&!this.state.captcha) } color="primary">Enviar</Button>
                                    </Col>
                                    {this.props.FuncionCerrar ?
                                        <Col sm={this.props.Usuario && this.props.IngresarLectura ? 6 : 3}>
                                            <Button block color="secondary" onClick={() => { this.props.FuncionCerrar() }}>Cancelar</Button>
                                        </Col> : ""}
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </CardBody>
                {/* <CardFooter className="text-muted">Footer</CardFooter> */}
            </Card>
        </div>)
    }
}


export default withRouter(IngresaLectura);