import React, { Component } from 'react';
import { TabContent, Alert, TabPane, Card, CardHeader, CardBody, Spinner, NavItem, NavLink, Nav, NavbarToggler, Form, FormGroup, Label, Row, Col, Input, Button, Navbar, Collapse } from 'reactstrap';
import { withRouter, Switch } from 'react-router';
import _ from 'underscore';
import { Global } from '~/App'
import API from '~/API';
import { Link } from 'react-router-dom';
import IngresaCuenta from '~/components/IngresaCuenta'
import Login from '~/Usuario/Login'

class Detalle extends Component {
    constructor() {
        super();
        this.state = {
            collapsed: false,
            Convenios: null
        }
    }
    componentWillMount() {
        var matches = this.props.location?.search?.match(/empresa=([^&]*).*?servicio=([^&]*)(.*seccion=([^&]*))*/i) || []
        if (matches.length >= 3) {
            var Empresa = Global.empresas.find(o => o.key == matches[1])
            this.props.history.replace({
                pathname: "/detalle"+(matches[4] ? ("/"+matches[4]) : ""),
                state: { servicio: { empresa: matches[1], idServicio: matches[2], Empresa, nombreEmpresa: Empresa.nombre } }
            })
        } else {
            this.Redirect(this.props);
            this.consultaConvenios(this.props);
        }
    }
    componentWillReceiveProps(props) {
        var Servicio = (this.props.location.state || {}).servicio
        var ServicioN = (props.location.state || {}).servicio
        if (Servicio?.idServicio != ServicioN?.idServicio) {
            this.setState({ Convenios: null })
        }
        this.Redirect(props);
        this.consultaConvenios(props);
    }
    async consultaConvenios(props) {
        var Servicio = (props.location.state || {}).servicio
        if (!Servicio.idServicio || !Servicio.empresa) {
            return;
        }
        if (!this.state.Convenios) {
            var Convenios = await API.Call(`Consulta/${Servicio.empresa}/Convenios/${Servicio.idServicio}`);
            this.setState({ Convenios });
        }
    }
    Redirect(props) {
        if (!props.location.state || !props.location.state.servicio || (props.Usuario && !props.Servicios.some(e => e.empresa?.toLowerCase() == props.location.state.servicio.empresa?.toLowerCase() && e.idServicio == props.location.state.servicio.idServicio))) {
            props.history.replace("/sucursal");
        }
    }
    render() {
        var servicio = this.props.location.state ? this.props.location.state.servicio : {};
        servicio = { ...servicio, Empresa: servicio.Empresa ? servicio.Empresa : Global.empresas.find(o => o.key == servicio.empresa) }
        var Navegacion = (
            <Nav vertical>
                <NavItem>
                    <NavLink tag={Link} to={{ pathname: "/detalle/documentos", state: { servicio } }}>Documentos</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to={{ pathname: "/detalle/pagos", state: { servicio } }}>Pagos</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} to={{ pathname: "/detalle/consumos", state: { servicio } }}>Consumos</NavLink>
                </NavItem>
                {servicio.e_mail !== undefined && <NavItem>
                    <NavLink tag={Link} to={{ pathname: "/detalle/email", state: { servicio } }}>Envío por E-Mail</NavLink>
                </NavItem>}
                {(this.state.Convenios || []).length > 0 && <NavItem>
                    <NavLink tag={Link} to={{ pathname: "/detalle/convenios", state: { servicio } }}>Mis Convenios</NavLink>
                </NavItem>}
                {servicio.Empresa?.otros?.Requerimientos !== undefined ? <NavItem>
                    <NavLink tag={Link} to={{ pathname: "/detalle/requerimientos", state: { servicio } }}>Mis Requerimientos</NavLink>
                </NavItem> : null}
                {servicio.Empresa?.otros?.Suscripciones !== undefined ? <NavItem>
                    <NavLink tag={Link} to={{ pathname: "/detalle/suscripciones", state: { servicio } }}>Mis Pagos Automáticos</NavLink>
                </NavItem> : null}
            </Nav>
        )
        var servicio = (this.props.location.state || {}).servicio;
        if(!servicio) return <Spinner animation="border" role="status">Cargando...</Spinner>
        if (!this.props.Usuario) {
            return <Card>
                <CardHeader className="titulo-modal">Sucursal Virtual</CardHeader>
                <CardBody className="cuerpo-modal">
                    <Row>
                        <Col sm={{ size: 6, offset: 3 }} className="bordeCompleto">
                            <h4 className="text-muted"><small>Ingrese con su usuario y contraseña</small></h4>
                            <br />
                            {this.props.location.state && _.map(this.props.location.state.mensajes, (e, i) => <Alert key={i} color={"warning"}>{e}</Alert>)}
                            <Login />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        }
        return (
            <div>
                <Row>
                    <Col md={2} className="bordeIzquierdoSimple d-none d-lg-block"> {/* Version grande */}
                        {Navegacion}
                    </Col>
                    <Col sm={2} className="d-lg-none"> {/* Version movil */}
                        <Button color="secondary" onClick={() => this.setState({ collapsed: !this.state.collapsed })} className="float-right">≡</Button>
                        <Collapse isOpen={!this.state.collapsed}>
                            {Navegacion}
                        </Collapse>
                    </Col>
                    <Col md={10} sm={12} xs={12}>
                        <Card>
                            <CardHeader className="titulo-modal">
                                <Row>
                                    <Col sm={4}><h4><span className="tituloCuenta">Cuenta</span> {Global.AgregaDV(servicio.idServicio)}</h4>
                                        <h4 className="text-white"><small>{servicio.nombreEmpresa}</small></h4>
                                    </Col>
                                    <Col sm={8}>
                                        {servicio.nom_cliente && <Row>
                                            <Col xs={3} className="text-right"><strong>Titular</strong></Col>
                                            <Col xs={9}>{Global.toTitleCase(servicio.nom_cliente)}</Col>
                                        </Row>}
                                        {servicio.dir_instal &&
                                            <Row>
                                                <Col xs={3} className="text-right direccion-domicilio"><strong><span>Dirección</span></strong></Col>
                                                <Col xs={9}>{Global.toTitleCase(servicio.dir_instal)}, {Global.toTitleCase(servicio.comuna_instal)}</Col>
                                                <br />
                                            </Row>}
                                    </Col>
                                </Row>
                                <br />
                            </CardHeader>
                            <CardBody className="cuerpo-modal">
                                <Switch>
                                    {
                                        React.Children.map(this.props.children, child => {
                                            return React.cloneElement(child, { passedProps: { Convenios: this.state.Convenios, Servicio: this.props.location.state ? this.props.location.state.servicio : {} } })
                                        })
                                    }
                                </Switch>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}


export default withRouter(Detalle);