import axios from './Axios';
import Cookies from 'universal-cookie';
import moment from 'moment';

const errorMessage = {
    401: "Autentificación incorrecta. Por favor, inténtalo nuevamente.",
    403: "No tienes permiso para ver esta sección.",
    415: "El usuario se encuentra activo en el sistema actualmente.",
    419: "El usuario no tiene permiso para esta sección.",
    default: "Ocurrió un problema al rescatar los datos desde el servidor."
}
const cookies = new Cookies();

async function SetHeaderBearer(token, expires = null){
    if(expires){
        var expiracionCookie = new Date(expires);
        cookies.set('asy_recaudador', token, { /* path: '/',  */expires: expiracionCookie, sameSite: 'none', secure: true});
        API.Cookie = token;
        API.ExpiracionCookie = moment(expires);
    } else if (!API.Cookie){
        API.Cookie = token;
        API.ExpiracionCookie = moment(expires);
    }
    axios.defaults.headers.common['Authorization'] = token ? "Bearer " + token : "";
}
function BorrarCookie(){
    API.Cookie = null;
    API.ExpiracionCookie = null;
    axios.defaults.headers.common['Authorization'] = null;
    cookies.remove('asy_recaudador'/* , { path: '/' } */);
}
async function RevisarCookie(){
    if (!API.Cookie && cookies.get('asy_recaudador')) {
        await API.SetHeaderBearer(cookies.get('asy_recaudador'));
    }
}
async function Call(url, data) {
    if(API.ExpiracionCookie && API.ExpiracionCookie < moment()){
        BorrarCookie();
    }
    var method = data ? 'post' : 'get';
    var xhr = axios[method](`${API.UrlBase}/${url}`, data)
        .then((response) => {
                return response.data;
        })
        .catch(error => {
            if(error.response.headers){
                let autenticate = error.response.headers["www-authenticate"];
                if(autenticate && autenticate.includes('Bearer error="invalid_token"')){
                    //token malo
                    BorrarCookie();
                }
            }
            var mensajeErrorFinal = "";
            try {
                var errorCode = error.status ? error.status : error.toString().substring(error.toString().lastIndexOf("code") + 5);//revisar por que + 5
                mensajeErrorFinal = errorMessage[errorCode];
                mensajeErrorFinal = mensajeErrorFinal ? mensajeErrorFinal : errorMessage.default;
            } catch (exception) {
                mensajeErrorFinal = errorMessage.default;
            }
            try{
                return (Promise.reject(error.response.data.Mensaje || error.response.data.Message || mensajeErrorFinal))
            }catch{
                return (Promise.reject(mensajeErrorFinal))
            }
        })
    return xhr;
};

const API = {
    Call: (url, data = null) => Call(url, data),
    SetHeaderBearer: (token, expires) => SetHeaderBearer(token, expires),
    BorrarCookie: () => BorrarCookie(),
    RevisarCookie: () => RevisarCookie(),
    MensajeError: (numero) => errorMessage[numero],
    ExpiracionCookie: null,
    Cookie: null,
    UrlBase: null
};

export default API;